<template>
  <v-navigation-drawer
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
    :expand-on-hover="mini"
    :right="$vuetify.rtl"
    :value="drawer"
    app
    class="my-4 ms-4 border-radius-lg"
    fixed
    floating
    width="100%"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
              class="navbar-brand-img ms-3"
              src="@/assets/dynalogo.svg"
              width="100%"
            >
            </v-img>
            <!-- <span class="ms-2 font-weight-bold text-sm">Dynapark</span> -->
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr class="horizontal dark mb-4"/>

    <v-list dense nav>
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :ripple="false"
        active-class="item-active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
      >
        <template v-slot:activator>
          <v-list-item-icon class="shadow border-radius-md mx-2 align-center">
            <div v-html="item.action">
              {{ item.action }}
            </div>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="ms-1"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :ripple="false"
          :to="child.link"
          class="mb-0 no-default-hover"
          link
        >
          <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content v-if="!child.items" class="ms-6 ps-7">
            <v-list-item-title
              @click="listClose($event)"
              v-text="child.title"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content v-if="child.items" class="ms-6 ps-7 py-0">
            <v-list-group
              v-model="child.active"
              :ripple="false"
              no-action
              prepend-icon=""
              sub-group
            >
              <template v-slot:activator>
                <span class="v-list-item-mini">{{ child.prefix }}</span>
                <v-list class="pa-0" dense nav>
                  <v-list-group
                    :ripple="false"
                    active-class="item-active card-shadow"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :key="child2.title"
                :ripple="false"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item-group>
        <div v-for="(item, i) in itemsDocs" :key="i">
          <v-list-item
            v-if="!item.external"
            :ripple="false"
            :to="item.link"
            active-class="item-active"
            class="pb-1 mx-2 no-default-hover"
            link
          >
            <v-list-item-icon class="shadow border-radius-md mx-2 align-center">
              <div v-html="item.action">
                {{ item.action }}
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="ms-1"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="item.external"
            :href="item.link"
            :ripple="false"
            active-class="item-active"
            class="pb-1 mx-2 no-default-hover"
            link
            target="_blank"
          >
            <v-list-item-icon class="shadow border-radius-md mx-2 align-center">
              <div v-html="item.action">
                {{ item.action }}
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="ms-1"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>

      <!-- GESTION DES PARKINGS -->
      <div v-if="isAdmin">
        <h5
          class="
            text-uppercase text-caption
            ls-0
            font-weight-bolder
            p-0
            text-muted
            mx-4
            mt-4
            mb-2
            ps-2
            d-none-mini
            white-space-nowrap
          "
        >
          GESTION DES PARKINGS
        </h5>

        <v-list-item-group>
          <div v-for="(item, i) in gestionParking" :key="i">
            <v-list-item
              v-if="!item.external"
              :ripple="false"
              :to="item.link"
              active-class="item-active "
              class="pb-1 mx-2 no-default-hover"
              link
            >
              <v-list-item-icon
                class="shadow border-radius-md mx-2 align-center"
              >
                <div v-html="item.action">
                  {{ item.action }}
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="ms-1"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="item.external"
              :href="item.link"
              :ripple="false"
              active-class="item-active"
              class="pb-1 mx-2 no-default-hover"
              link
              target="_blank"
            >
              <v-list-item-icon
                class="shadow border-radius-md mx-2 align-center"
              >
                <div v-html="item.action">
                  {{ item.action }}
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="ms-1"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </div>

      <!-- ADMINISTRATION -->
      <div v-if="isAdmin">
        <h5
          class="
            text-uppercase text-caption
            ls-0
            font-weight-bolder
            p-0
            text-muted
            mx-4
            mt-4
            mb-2
            ps-2
            d-none-mini
            white-space-nowrap
          "
        >
          ADMINISTRATION
        </h5>

        <v-list-item-group>
          <div v-for="(item, i) in administration" :key="i">
            <v-list-item
              v-if="!item.external"
              :ripple="false"
              :to="item.link"
              active-class="item-active"
              class="pb-1 mx-2 no-default-hover"
              link
            >
              <v-list-item-icon
                class="shadow border-radius-md mx-2 align-center"
              >
                <div v-html="item.action">
                  {{ item.action }}
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="ms-1"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="item.external"
              :href="item.link"
              :ripple="false"
              active-class="item-active"
              class="pb-1 mx-2 no-default-hover"
              link
              target="_blank"
            >
              <v-list-item-icon
                class="shadow border-radius-md mx-2 align-center"
              >
                <div v-html="item.action">
                  {{ item.action }}
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="ms-1"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    itemsDocs: [
      {
        action:
          '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 20V22H2V20H3V13.242C2.38437 12.8311 1.87971 12.2745 1.53082 11.6218C1.18193 10.969 0.999592 10.2402 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.43277 2.34798 4.559 2.22175 4.71101 2.13398C4.86303 2.04621 5.03547 2 5.211 2H18.79C18.9655 2 19.138 2.04621 19.29 2.13398C19.442 2.22175 19.5682 2.34798 19.656 2.5L22.358 7.182C22.9546 8.17287 23.1463 9.35553 22.8934 10.4841C22.6405 11.6127 21.9624 12.6005 21 13.242V20H22ZM5.789 4L3.356 8.213C3.11958 8.79714 3.11248 9.44903 3.33613 10.0382C3.55978 10.6273 3.99768 11.1103 4.56218 11.3904C5.12668 11.6705 5.77614 11.7271 6.38058 11.5488C6.98502 11.3706 7.49984 10.9706 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C9.8633 10.8934 10.1836 11.2916 10.5975 11.5721C11.0115 11.8526 11.5 12.0025 12 12.0025C12.5 12.0025 12.9885 11.8526 13.4025 11.5721C13.8164 11.2916 14.1367 10.8934 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.3078 10.7484 16.5022 11.0376 16.7491 11.2783C16.996 11.519 17.2901 11.706 17.6127 11.8275C17.9354 11.9491 18.2797 12.0026 18.6241 11.9847C18.9684 11.9668 19.3053 11.8778 19.6136 11.7234C19.9219 11.569 20.1949 11.3525 20.4155 11.0875C20.6361 10.8225 20.7995 10.5148 20.8955 10.1836C20.9914 9.85238 21.0178 9.50493 20.973 9.16305C20.9281 8.82118 20.8131 8.49227 20.635 8.197L18.21 4H5.79H5.789Z" fill="#03053D"/></svg>',
        link: "/home",
        title: "Accueil",
        external: false,
      },
    ],
    administration: [
      {
        action:
          '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 22C2 19.8783 2.84285 17.8434 4.34315 16.3431C5.84344 14.8429 7.87827 14 10 14C12.1217 14 14.1566 14.8429 15.6569 16.3431C17.1571 17.8434 18 19.8783 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM17.363 15.233C18.8926 15.6261 20.2593 16.4918 21.2683 17.7068C22.2774 18.9218 22.8774 20.4242 22.983 22H20C20 19.39 19 17.014 17.363 15.233ZM15.34 12.957C16.178 12.2075 16.8482 11.2893 17.3066 10.2627C17.765 9.23616 18.0013 8.12429 18 7C18.0021 5.63347 17.6526 4.28937 16.985 3.097C18.1176 3.32459 19.1365 3.93737 19.8685 4.8312C20.6004 5.72502 21.0002 6.84473 21 8C21.0003 8.71247 20.8482 9.41676 20.5541 10.0657C20.26 10.7146 19.8305 11.2931 19.2946 11.7625C18.7586 12.2319 18.1285 12.5814 17.4464 12.7874C16.7644 12.9934 16.0462 13.0512 15.34 12.957Z" fill="#03053D"/></svg>',
        link: "/users",
        title: "Utilisateurs",
        external: false,
      },
      // {
      //   action:
      //     '<svg width="12px" height="12px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.75001 5.5L4.82201 5.5035C4.99532 5.5201 5.15746 5.59649 5.28062 5.71955C5.40377 5.84262 5.48028 6.0047 5.49701 6.178L5.50001 6.25L5.49651 6.322C5.47991 6.49531 5.40352 6.65745 5.28046 6.78061C5.15739 6.90376 4.99531 6.98027 4.82201 6.997L4.75001 7H3.00001V8H4.75001C5.60001 8 6.30851 7.394 6.46701 6.5905L6.48201 6.5005L9.50001 6.5C9.85551 6.5 10.194 6.4255 10.5 6.292V8.5C10.5 8.81022 10.4038 9.11282 10.2247 9.36613C10.0457 9.61944 9.79247 9.81102 9.50001 9.9145V10.5C9.50001 10.6326 9.44733 10.7598 9.35357 10.8536C9.2598 10.9473 9.13262 11 9.00001 11H3.00001C2.8674 11 2.74023 10.9473 2.64646 10.8536C2.55269 10.7598 2.50001 10.6326 2.50001 10.5V9.915C2.20747 9.81149 1.95422 9.61984 1.77513 9.36643C1.59604 9.11301 1.49992 8.81031 1.50001 8.5V6.5C1.50001 6.23478 1.60537 5.98043 1.7929 5.79289C1.98044 5.60536 2.2348 5.5 2.50001 5.5H4.75001ZM11 3.75V4L10.9975 4.088C10.976 4.4548 10.8206 4.80093 10.5608 5.06074C10.3009 5.32055 9.95481 5.47596 9.58801 5.4975L9.50001 5.5H6.33151C6.1971 5.21672 5.98907 4.97475 5.72916 4.79937C5.46925 4.62398 5.16701 4.52163 4.85401 4.503L4.75001 4.5H2.50001C2.13551 4.5 1.79401 4.5975 1.49951 4.768L1.50001 3C1.50001 2.46957 1.71073 1.96086 2.0858 1.58579C2.46087 1.21071 2.96958 1 3.50001 1H8.25001C8.97936 1 9.67883 1.28973 10.1946 1.80546C10.7103 2.32118 11 3.02065 11 3.75Z" fill="#03053D"/></svg>',
      //   link: "/parking-augmenté",
      //   title: "Parking Augmenté",
      //   external: false,
      // },
      // {
      //   action:
      //     '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 10V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V10H22ZM22 8H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V8ZM15 16V18H19V16H15Z" fill="#03053D"/></svg>',
      //   link: "/facturation",
      //   title: "Facturation",
      //   external: false,
      // },
    ],
    gestionParking: [
      {
        action:
          '<svg width="12px" height="12px" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6.999V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H11V6.999H20ZM9 12.999V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V12.999H9ZM9 0V10.999H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H9ZM19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V4.999H11V0H19Z" fill="#03053D"/></svg>',
        link: "/dashboard",
        title: "Tableau de bord",
        external: false,
      },
      // {
      //   action:
      //     '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 18H4V10H9V18ZM15 18H10V6H15V18ZM21 18H16V2H21V18ZM22 22H3V20H22V22Z" fill="#03053D"/></svg>',
      //   link: "/rapports",
      //   title: "Rapports",
      //   external: false,
      // },
      // {
      //   action:
      //     '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 7.99999C21.5304 7.99999 22.0391 8.21071 22.4142 8.58578C22.7893 8.96085 23 9.46956 23 9.99999V14C23 14.5304 22.7893 15.0391 22.4142 15.4142C22.0391 15.7893 21.5304 16 21 16H19.938C19.6942 17.9333 18.7533 19.7112 17.2917 21C15.8302 22.2888 13.9486 23 12 23V21C13.5913 21 15.1174 20.3679 16.2426 19.2426C17.3679 18.1174 18 16.5913 18 15V8.99999C18 7.40869 17.3679 5.88257 16.2426 4.75735C15.1174 3.63213 13.5913 2.99999 12 2.99999C10.4087 2.99999 8.88258 3.63213 7.75736 4.75735C6.63214 5.88257 6 7.40869 6 8.99999V16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V9.99999C1 9.46956 1.21071 8.96085 1.58579 8.58578C1.96086 8.21071 2.46957 7.99999 3 7.99999H4.062C4.30603 6.06689 5.24708 4.28926 6.70857 3.00067C8.17007 1.71207 10.0516 1.00107 12 1.00107C13.9484 1.00107 15.8299 1.71207 17.2914 3.00067C18.7529 4.28926 19.694 6.06689 19.938 7.99999H21ZM7.76 15.785L8.82 14.089C9.77308 14.686 10.8754 15.0018 12 15C13.1246 15.0018 14.2269 14.686 15.18 14.089L16.24 15.785C14.9693 16.5811 13.4995 17.0023 12 17C10.5005 17.0023 9.03074 16.5811 7.76 15.785Z" fill="#03053D"/></svg>',
      //   link: "/assistance",
      //   title: "Assistance",
      //   external: false,
      // },
    ],
  }),

  computed: {
    isAdmin() {
      if (this.$store.getters.isLogged) {
        let isAdminBool = this.$store.getters.userRole.toLowerCase() === "admin";
        console.log(`is admin: ${isAdminBool}`);
        return isAdminBool;
      } else {
        return false;
      }
    },
  },

  methods: {
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>

<style>
.nav-selected {
  --shift: 5px;
  --color-value: 100;
  --opacity: 0.1;
  box-shadow: var(--shift) var(--shift) var(--shift) rgba(
    var(--color-value),
    var(--color-value),
    var(--color-value),
    var(--opacity)
  );
  -webkit-box-shadow: var(--shift) var(--shift) var(--shift) rgba(
    var(--color-value),
    var(--color-value),
    var(--color-value),
    var(--opacity)
  );
  -moz-box-shadow: var(--shift) var(--shift) var(--shift) rgba(
    var(--color-value),
    var(--color-value),
    var(--color-value),
    var(--opacity)
  );
}
</style>
